import { Component, OnInit } from '@angular/core';
import { LogInventarioService } from 'src/app/services/logInventario/log-inventario.service';
import { ProductoService } from 'src/app/services/productos/producto.service';
import { VentasService } from 'src/app/services/ventas/ventas.service';
import swal from 'sweetalert';
declare var $: any;

@Component({
  selector: 'app-logs-de-inventario',
  templateUrl: './logs-de-inventario.component.html',
  styleUrls: ['./logs-de-inventario.component.css']
})
export class LogsDeInventarioComponent implements OnInit {

  // Variables
  ventaSeleccionada;

  // Data
  logs: any[];

  // Paginado
  paginaActual: number;
  totalPages: number;
  totalDeLogs: number;

  // Buscador
  termino: string;
  productos: any[];
  productoSeleccionado: any;

  constructor(
    private _logInventarioService: LogInventarioService,
    private _productoService: ProductoService,
    private _ventaService: VentasService
  ) { }

  ngOnInit(): void {
    this.obtenerLogsDeInventario( 1 );
    this.paginaActual = 1;
  }

  seleccionarVenta( idVenta ) {
    this._ventaService.obtenerVentaPorId( idVenta ).subscribe(
      (resp: any) => {
        this.ventaSeleccionada = resp.venta;
        $('#modalInfoVenta').modal('toggle');
    },
    (error) => {
      swal(
        'Error al buscar venta',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  buscarProductos() {
    const termino = this.termino;

    if ( termino === '') {
      this.productos = null;
      this.productoSeleccionado = '';
      this.obtenerLogsDeInventario( 1 );
      return;
    }

    this._productoService.buscarProducto( termino ).subscribe(
      (resp: any) => {
        this.productos = resp.producto;
    },
    (error) => {
      swal(
        'Error al buscar productos',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  obtenerLogsDeInventario( pagina, producto = '' ) {
    this._logInventarioService.obtenerLogsDeInventario( pagina, producto ).subscribe(
      (resp: any) => {
        this.logs = resp.logs;
        this.totalDeLogs = resp.totalLogs;
        this.totalPages = Math.ceil( ( this.totalDeLogs / 10 ) );
        this.productoSeleccionado = producto;
        this.productos = null;
    },
    (error) => {
      swal(
        'Error al obtener movimientos de inventario',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  paginaAnterior( producto = '' ) {
    if (this.paginaActual === 1) {
      return;
    }

    if ( this.productoSeleccionado ) {
      producto = this.productoSeleccionado;
    }

    this.paginaActual -= 1;
    this.obtenerLogsDeInventario( this.paginaActual, producto );
  }

  paginaSiguiente( producto = '' ) {
    if (this.paginaActual === this.totalPages) {
      return;
    }

    if (this.productoSeleccionado) {
      producto = this.productoSeleccionado;
    }

    this.paginaActual += 1;
    this.obtenerLogsDeInventario( this.paginaActual, producto );
  }



}
