import { Component, OnInit } from '@angular/core';
import { LogInventarioService } from 'src/app/services/logInventario/log-inventario.service';
import { ProductoService } from 'src/app/services/productos/producto.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-punto-de-reorden',
  templateUrl: './punto-de-reorden.component.html',
  styleUrls: ['./punto-de-reorden.component.css']
})
export class PuntoDeReordenComponent implements OnInit {

  // Paginado
  paginaActual: number;
  totalPages: number;
  totalDeProductos: number;

  // Buscador
  termino: string;
  productosBuscador: any[];
  productoSeleccionado: any;

  // Data
  productos: any[];
  productosPorSurtir: any[];

  constructor(
    private _productoService: ProductoService,
    private _logInventarioService: LogInventarioService
  ) { }

  ngOnInit(): void {
    this.obtenerProductos( 1, 10 );
    this.obtenerProductosPorSurtir();
  }

  buscarProductos() {
    const termino = this.termino;

    if (termino === '') {
      this.productosBuscador = null;
      this.productoSeleccionado = '';
      return;
    }

    this._productoService.buscarProducto(termino).subscribe(
      (resp: any) => {
        this.productosBuscador = resp.producto;
      },
      (error) => {
        swal(
          'Error al buscar productos',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  obtenerProductos( pagina, limit = 10 ) {
    this._productoService.obtenerProductosPaginados( pagina, limit ).subscribe(
      (resp: any) => {
        this.productos = resp.productos;
        this.totalDeProductos = resp.totalProductos;
        this.totalPages = Math.ceil( this.totalDeProductos / 10 );
        this.paginaActual = pagina;
    },
    (error) => {
      swal(
        'Error al obtener movimientos de inventario',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  obtenerProductosPorSurtir() {
    this._productoService.obtenerProductosPorSurtir().subscribe(
      (resp: any) => {
        this.productosPorSurtir = resp.productosPorSurtir;
    },
    (error) => {
      swal(
        'Error al obtener productos por surtir',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  seleccionarProducto( producto ) {
    this.productosBuscador = [];
    this.productoSeleccionado = producto;
    this.productos = [];
    this.productos.push( producto );
  }

  paginaAnterior(producto = '') {
    if (this.paginaActual === 1) {
      return;
    }

    if (this.productoSeleccionado) {
      producto = this.productoSeleccionado;
    }

    this.paginaActual -= 1;
    this.obtenerProductos(this.paginaActual);
  }

  paginaSiguiente(producto = '') {
    if (this.paginaActual === this.totalPages) {
      return;
    }

    if (this.productoSeleccionado) {
      producto = this.productoSeleccionado;
    }

    this.paginaActual += 1;
    this.obtenerProductos(this.paginaActual);
  }

  cambiarPuntoDeReorden( producto ) {
    swal({
      content: {
        element: "input",
        attributes: {
          type: "number"
        }
      },
      text: 'Cambiar punto de reorden',
      buttons: [true, 'Aceptar']
    })
    .then( nuevoPuntoDeReorden => {

      const productoActualizado = {
        _id: producto._id,
        puntoDeReorden: nuevoPuntoDeReorden
      };

      this._productoService.actualizarProducto( productoActualizado ).subscribe(
        ( resp: any ) => {
          this.obtenerProductos( this.paginaActual );
      },
      (error) => {
        swal(
          'Error al cambiar punto de reorden',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
    });
  }

}
