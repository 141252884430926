import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaxObjectService {
  public taxObjects = [
    { name: 'No objeto de impuesto', value: '01' },
    { name: 'Si objeto de impuesto', value: '02' },
    { name: 'Si objeto de impuesto, y no obligado al desglose de IVA', value: '03' },
    { name: 'Si objeto de impuesto y no causa impuesto', value: '04' },
  ];

  constructor() { }
}
