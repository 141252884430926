import { Injectable } from '@angular/core';
import {ISSUER_RFC, MicroServicesAPI} from '../config/config';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvoiceIssuerService {

  entrypoint: string;
  constructor(
    private http: HttpClient
  ) {
    this.entrypoint = `${MicroServicesAPI}/invoice`;
  }


  public getInvoiceIssuer() {
    const url = `${this.entrypoint}/invoiceIssuer?rfc=${ISSUER_RFC}`;
    return this.http.get(url);
  }

  public createInvoiceIssuer(invoiceIssuer: any) {
    const url = `${this.entrypoint}/registerInvoiceIssuer`;
    return this.http.post(url, invoiceIssuer);
  }

  public deleteInvoiceIssuer(invoiceIssuerId: string) {
    const url = `${this.entrypoint}/deleteInvoiceIssuer/${invoiceIssuerId}`;
    return this.http.delete(url);
  }
}
