import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MicroServicesAPI} from '../config/config';
import {ProductoService} from './productos/producto.service';
import swal from 'sweetalert';
import {InvoiceIssuerService} from './invoice-issuer.service';
export enum facturamaInvoiceStatus {
  all = 'all',
  active = 'active',
  cancel = 'cancel',
  pending = 'pending',
  invalid = 'invalid',
  unanswered = 'unanswered',
}

@Injectable({
  providedIn: 'root'
})
export class Invoice2023Service {
  endpoint = `${MicroServicesAPI}/invoice`;
  issuerId: number;
  issuerTaxZipCode: number;
  logoUrl = 'https://bessonart.digitalsystem.mx/assets/images/bessonart-logo.jpg';


  constructor(
    private http: HttpClient,
    private _productService: ProductoService,
    private invoiceIssuerService: InvoiceIssuerService
  ) {

  }

  public async setIssuerData() {
    if (this.issuerId && this.issuerTaxZipCode) {
      return;
    }
    const invoiceIssuer = await this.invoiceIssuerService.getInvoiceIssuer().toPromise() as any;
    this.issuerId = invoiceIssuer.id;
    this.issuerTaxZipCode = invoiceIssuer.postalCode;
  }


  public async createInvoice(sale: string) {
   const parsedSale = await this.parseSaleForFacturama(sale);
   if (!parsedSale?.receiver?.fiscalRegime) {
     swal(
       "El cliente no cuenta con régimen fiscal",
       "Favor de editar el cliente y agregarlo antes de crear la factura",
       "warning"
     );
     throw new Error('Client without fiscal regime');
   }

   if (!parsedSale?.receiver?.taxZipCode) {
      swal(
        "El cliente no cuenta con código postal",
        "Favor de editar el cliente y agregarlo antes de crear la factura",
        "warning"
      );
      throw new Error('Client without tax zip code');
   }

   try {
     const result = await this.http.post(`${this.endpoint}/createInvoice`, parsedSale).toPromise();
     return result;
   } catch (error) {
     const errorMessage = error?.error?.Message;
     console.log('Error', errorMessage);
     swal(
       "Error al crear la factura",
       error.error.Message,
       "error"
     );
     throw new Error(errorMessage);
   }
  }

  public cancelInvoice(invoiceId: string, motive: string, uuidReplacement?: string) {
    let url = `${this.endpoint}/cancel/${invoiceId}?motive=${motive}`;
    if (uuidReplacement) {
      url += `&uuidReplacement=${uuidReplacement}`;
    }
    return this.http.delete(url);
  }

  public getInvoices(page: number, limit: number, statusFilter?: facturamaInvoiceStatus) {
    if (!this?.issuerId || !this?.issuerTaxZipCode) {
      this.setIssuerData();
    }
    let url = `${this.endpoint}/getInvoices?page=${page}&limit=${limit}&issuer=${this.issuerId}`;
    if (statusFilter) {
      url += `&status=${statusFilter}`;
    }
    return this.http.get(url);
  }

  // Private methods
  private async parseSaleForFacturama(sale: any) {
    for (const item of sale.carrito) {
      const response = await this._productService.getProductSatCode(item._id).toPromise() as any; // TODO add proper product interface
      if (!response?.satCode) {
       swal(
         "Alguno(s) de los productos no cuenta con código SAT",
         "Favor de editar los productos y agregarlo antes de crear la factura",
         "warning"
       );
       throw new Error('Product without sat code');
      }
      item.satCode = response?.satCode;
    }
    console.log('***carrito', sale.carrito);
    return {
      nameId: 1,
      cfdiType: 'I',
      expeditionPlace: this.issuerTaxZipCode,
      paymentForm: sale.formaDePagoFacturacion,
      paymentMethod: sale.metodoDePagoFacturacion,
      currency: 'MXN',
      logoUrl: this.logoUrl,
      issuerId: this.issuerId,
      receiver: {
        rfc: sale.cliente.rfc,
        cfdiUse: sale.usoCFDI,
        name: sale.cliente.razonSocial?.trim()?.toUpperCase(),
        fiscalRegime: sale.cliente.fiscalRegime,
        taxZipCode: sale.cliente.taxZipCode,
        email: sale.cliente.correoFacturacion,
      },
      items: sale.carrito.map(item => {
        const unitDiscount = +item.descuento.toFixed(2);
        const unitPrice = +(Number(item.precio) - unitDiscount).toFixed(2);
        const subtotal = +(unitPrice * item.cantidad).toFixed(2);
        const total = +(subtotal * 1.16).toFixed(2);
        const totalDiscount = +(unitDiscount * item.cantidad).toFixed(2);
        if (!item?.satCode) {
          const productSatCode = this._productService.getProductSatCode(item?.producto);
          console.log('productSatCode', productSatCode);
          item.satCode = productSatCode;
        }
       return {
         Discount: 0,
         Description: item.nombre,
         ProductCode: item.satCode,
         UnitCode: 'H87', // TODO default to pieza, check this with Martin
         Unit: 'Pieza',
         Quantity: item.cantidad,
         UnitPrice: unitPrice,
         Subtotal: subtotal,
         Total: total,
         TaxObject: item?.taxObject || '02',
         Taxes: [
           {
             Name: 'IVA',
             Rate: 0.16,
             Total: +(subtotal * 0.16).toFixed(2),
             Base: subtotal,
             IsRetention: false,
             IsFederalTax: true,
             IsQuota: false,
           },
         ],
       };
      }),
      decimals: 2,
      exportation: '01',
      date: new Date(),
    };
  }


  private roundNumber(number: number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

}
