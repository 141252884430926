// URL Producción
export const URL_SERVICIOS = 'https://bessonartapi.digitalsystem.mx';

// URL Development
// export const URL_SERVICIOS = 'http://localhost:3010';

// Invoicing microservice URL
export const MicroServicesAPI = 'https://officeapi.digitalsystem.mx';
// export const MicroServicesAPI = 'http://localhost:3020';

export const ISSUER_RFC = 'BES190326K63';
//export const ISSUER_RFC = 'EKU9003173C9';

// URL for download pdf and xml from facturama
export const FACTURAMA_DOWNLOAD_FILES_URL = 'https://api.facturama.mx/cfdi';
export const FACTURAMA_TOKEN ='Basic TWljaEZMOnhGNHhNTE1RcXNMVEx5NDpGYWN0dXJhbWFXZWI=';
