import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Cliente } from '../../../models/cliente.model';
import { UsuarioService } from '../../../services/usuarios/usuario.service';
import { ImageUploadService } from '../image-upload/image-upload.service';
import swal from 'sweetalert';
import { EstadosCiudadesService } from 'src/app/estados-ciudades.service';
import { SegmentosService } from 'src/app/segmentos.service';
import {FiscalRegimeService} from '../../../services/fiscal-regime.service';
declare var $: any;

@Component({
  selector: 'app-nuevo-cliente',
  templateUrl: './nuevo-cliente.component.html',
  styleUrls: ['./nuevo-cliente.component.css']
})
export class NuevoClienteComponent implements OnInit {

  @Input()
  toggleTicketModal = false;

  @Input()
  toggleModalVentaDetalle = false;

  @Output()
  clienteNuevo: EventEmitter<any> = new EventEmitter();

  @Output()
  imagenCliente: EventEmitter<any> = new EventEmitter();

  // Data
  fiscalRegimes;
  // ---

  mensajeRequeridos = 'Favor de completar el formulario';
  completo = false;

  nombre = '';
  telefono = '';
  direccion = '';
  correo = '';
  segmento = '';
  estado = '';
  ciudad = '';
  descuentoCliente = 0;
  emailFacturacion = '';
  razonSocial = '';
  rfc = '';
  fiscalRegime = '';
  taxZipCode = '';


  segmentos = [];

  imagenSubir: File;
  imagenTemporal: string | ArrayBuffer;

  // Variables para buscador drop down de estados

  ciudades;
  estados = [];

  estadosMunicipios = {};

  constructor(
    public _usuarioService: UsuarioService,
    public _imageUploadService: ImageUploadService,
    public _estadosCiudadesService: EstadosCiudadesService,
    public _segmentosService: SegmentosService,
    private fiscalRegimeService: FiscalRegimeService
  ) {
    this.fiscalRegimes = this.fiscalRegimeService.getFiscalRegimes();

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });

    this.estados = _estadosCiudadesService.estados;
    this.estadosMunicipios = _estadosCiudadesService.estadosMunicipios;
    this.segmentos = _segmentosService.segmentos;
  }

  ngOnInit() {}

  asignarDescuentoDelSegmento() {
    this.descuentoCliente = this._segmentosService.descuentosPorSegmento[this.segmento];
  }

  cargarCiudades() {

    this.ciudades = this.estadosMunicipios[this.estado].ciudades;
    this.validarFormulario();

  }

  agregarImagen() {

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    const promesa = new Promise( (resolve, reject) => {

      input.onchange = () => {

        const file: File = input.files[0];

        if (!file) {
          this.imagenSubir = null;
          this.imagenTemporal = null;
          return;
        }

        if (file.type.indexOf('image') < 0) {
          swal(
            'Típo de archivo inválido',
            'Seleccione una imágen',
            'error'
          );

          this.imagenSubir = null;
          return;
        }

        this.imagenSubir = file;
        const reader = new FileReader();
        const urlImagenTemporal = reader.readAsDataURL(file);

        reader.onloadend = () => this.imagenTemporal = reader.result;


      };

      input.click();
    });

    promesa.then();



  }

  resetearModal() {
    this.nombre = '';
    this.telefono = '';
    this.direccion = '';
    this.correo = '';
    this.segmento = '';
    this.segmentos = [];
    this.estado = '';
    this.ciudad = '';
    this.descuentoCliente = 0;
    this.imagenTemporal = null;
    this.imagenSubir = null;
    this.rfc = '';
    this.fiscalRegime = '';
    this.taxZipCode = '';
    this.razonSocial = '';

    if (this.toggleTicketModal) {
      $('#nuevoCliente').on('hidden.bs.modal', function(event) {
        // Open your second one in here
        $('#ticketVenta').modal('toggle');

        $('#nuevoCliente').off('hidden.bs.modal');
      });

    }

    if (this.toggleModalVentaDetalle) {
      $('#nuevoCliente').on('hidden.bs.modal', function (event) {
        // Open your second one in here
        $('#modalDetalleVenta').modal('toggle');

        $('#nuevoCliente').off('hidden.bs.modal');
      });

    }
  }

  validarFormulario() {
    if (this.nombre == '' || this.telefono == '' || this.direccion == '' || this.segmento == '' || this.estado == '' || this.ciudad == '' || this.descuentoCliente == null ) {
      this.completo = false;
    } else {
      this.completo = true;
    }
  }

  registrarCliente() {
    if ( !this.completo ) {
      return;
    }

    const nuevoCliente = new Cliente(
      this.nombre,
      this.telefono,
      this.direccion,
      this.estado,
      this.ciudad,
      this.segmento,
      this.descuentoCliente,
      this.correo,
      'Activo',
      null,
      null,
      this._usuarioService.id,
      this._usuarioService.id,
      this.emailFacturacion,
      this.razonSocial,
      this.rfc,
      this.fiscalRegime,
      this.taxZipCode
    );


    this.clienteNuevo.emit(nuevoCliente);

    if (this.imagenSubir) {

      this.imagenCliente.emit(this.imagenSubir);
    }

    $('#nuevoCliente').modal('toggle');

    this.resetearModal();
    this.validarFormulario();

  }

}
