import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductoService } from 'src/app/services/productos/producto.service';
import { UsuarioService } from 'src/app/services/usuarios/usuario.service';
import swal from 'sweetalert';
declare var $: any;

@Component({
  selector: 'app-editar-categorizacion',
  templateUrl: './editar-categorizacion.component.html',
  styleUrls: ['./editar-categorizacion.component.css']
})
export class EditarCategorizacionComponent implements OnInit {

  @Input()
  productoActual: any = {};

  @Output()
  validarProductoEnBaseDeDatos: EventEmitter<any> = new EventEmitter();

  // Data
  catalogoDeProductos: any = {};
  categoriasFinales = [];

  // Variables
  categoriasSeleccionadas = [];
  menuActual: any = {};

  constructor(
    private _productoService: ProductoService,
    private _usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.catalogoDeProductos = this._productoService.categorizacion;
    this.menuActual = this.catalogoDeProductos;
  }

  volverAIniciarSeleccion() {
    this.categoriasSeleccionadas = [];
    this.menuActual = this.catalogoDeProductos;
    console.log(
      this.productoActual.rutaDeCategorias
    );

  }

  volverAMenu(i) {

    const rutaMenu = this.categoriasSeleccionadas.slice(0, i + 1);
    this.menuActual = this.catalogoDeProductos;

    this.categoriasSeleccionadas = this.categoriasSeleccionadas.slice(0, i + 1);

    rutaMenu.forEach(menu => {
      this.menuActual = this.menuActual[menu];
    });


  }

  seleccionarCategoria(categoria) {

    if (this.categoriasSeleccionadas.indexOf(categoria) >= 0) {
      return;
    } else {
      this.categoriasSeleccionadas.push(categoria);
      this.menuActual = this.menuActual[categoria];
    }

  }

  actualizarProducto() {
    if (!this.menuActual.length) {
      return;
    }

    const producto = this.productoActual;

    producto.rutaDeCategorias = this.categoriasSeleccionadas;

    this._productoService.actualizarProducto( producto ).subscribe( (resp: any) => {

      $('#modalEditarCategoria').modal('toggle');

      swal(
        'Producto Creado Exitosamente',
        'El producto se ha registrado en la base de datos',
        'success'
      );

      if (this.productoActual.ARTICLE_DETAILS) {
        this.validarProductoEnBaseDeDatos.emit(this.productoActual.ARTICLE_DETAILS.EAN);
      } else {
        this.validarProductoEnBaseDeDatos.emit();
      }

      this.resetearModal();

    },
    (error) => {
      swal(
        'Error al crear producto',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });

  }

  agregarACategoriasAdicionales() {

    const categoriazacionActual = this.productoActual.rutaDeCategorias.map( categoria => categoria );

    this.categoriasSeleccionadas.forEach( ( categoria ) => {

      if (categoriazacionActual.indexOf(categoria) < 0 ) {
        categoriazacionActual.push(categoria);
      }
    });

    this.categoriasSeleccionadas = categoriazacionActual;

    const producto = this.productoActual;

    producto.rutaDeCategorias = categoriazacionActual;

    this._productoService.actualizarProducto(producto).subscribe( (resp: any) => {
      $('#modalEditarCategoria').modal('toggle');

      swal(
        'Producto Creado Exitosamente',
        'El producto se ha registrado en la base de datos',
        'success'
      );

      if (this.productoActual.ARTICLE_DETAILS  ) {
        this.validarProductoEnBaseDeDatos.emit(this.productoActual.ARTICLE_DETAILS.EAN);
      } else {
        this.validarProductoEnBaseDeDatos.emit();
      }

      this.resetearModal();
    },
    (error) => {
      swal(
        'Error al crear producto',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  resetearModal() {
    this.categoriasSeleccionadas = [];
    this.menuActual = this.catalogoDeProductos;
  }

}
