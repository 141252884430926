import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Producto } from 'src/app/models/producto.model';
import { ProductoService } from 'src/app/services/productos/producto.service';
import { UsuarioService } from 'src/app/services/usuarios/usuario.service';
import swal from 'sweetalert';
declare var $: any;

@Component({
  selector: 'app-modal-category-selection',
  templateUrl: './modal-category-selection.component.html',
  styleUrls: ['./modal-category-selection.component.css']
})
export class ModalCategorySelectionComponent implements OnInit {

  @Input()
  productoActual: any = {};

  @Output()
  validarProductoEnBaseDeDatos: EventEmitter<any> = new EventEmitter();

  // Data
  catalogoDeProductos: any = {};
  categoriasFinales = [];

  // Variables
  categoriasSeleccionadas = [];
  menuActual: any = {};

  constructor(
    private _productoService: ProductoService,
    private _usuarioService: UsuarioService
   ) { }

  ngOnInit(): void {
    this.catalogoDeProductos = this._productoService.categorizacion;
    this.menuActual = this.catalogoDeProductos;
  }

  volverAIniciarSeleccion() {
    this.categoriasSeleccionadas = [];
    this.menuActual = this.catalogoDeProductos;
  }

  volverAMenu(i) {

    const rutaMenu = this.categoriasSeleccionadas.slice(0, i + 1);
    this.menuActual = this.catalogoDeProductos;

    this.categoriasSeleccionadas = this.categoriasSeleccionadas.slice(0, i + 1);

    rutaMenu.forEach( menu => {
      this.menuActual = this.menuActual[menu];
    });


  }

  seleccionarCategoria( categoria ) {

    if ( this.categoriasSeleccionadas.indexOf( categoria ) >= 0 ) {
      return;
    } else {
      this.categoriasSeleccionadas.push( categoria );
      this.menuActual = this.menuActual[categoria];
    }

  }

  guardarProducto() {

    if ( !this.menuActual.length ) {
      return;
    }

    let rutaImagen = '';

    if ( this.productoActual.MIME_INFO.MIME.length > 0 ) {
      rutaImagen = this.productoActual.MIME_INFO.MIME[0].MIME_SOURCE;
    } else {
      rutaImagen = this.productoActual.MIME_INFO.MIME.MIME_SOURCE;
    }

    const referencia = this.normalizarReferencia(this.productoActual.ARTICLE_FEATURES.REFERENCE_FEATURE_GROUP_NAME );

    const nuevoProducto = new Producto(
      this.productoActual.ARTICLE_DETAILS.EAN,
      this.productoActual.ARTICLE_DETAILS.DESCRIPTION_SHORT,
      this.categoriasSeleccionadas[0],
      0,
      this._usuarioService.id,
      this._usuarioService.id,
      this.categoriasSeleccionadas,
      referencia,
      rutaImagen
    );

    this._productoService.registrarProducto( nuevoProducto ).subscribe(
      (resp) => {

        $('#modalSeleccionDeCategoria').modal('toggle');

        swal(
          'Producto Creado Exitosamente',
          'El producto se ha registrado en la base de datos',
          'success'
        );

        this.validarProductoEnBaseDeDatos.emit(this.productoActual.ARTICLE_DETAILS.EAN );
    },
    (error) => {
      swal(
        'Error al crear producto',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });


  }

  normalizarReferencia( referenciaLong: string ) {
    const referenciaNormalizada = referenciaLong.split( '-' )[0].trim();

    return referenciaNormalizada;
  }


  resetearModal() {
    this.categoriasSeleccionadas = [];
    this.menuActual = this.catalogoDeProductos;
  }

}
