import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SegmentosService {

  segmentos = [
    'Fabricante en Forma',
    'Taller de Carpintería',
    'Arquitecto / Despacho',
    'Diseñador / Decorador',
    'Ferreteras',
    'Tienda de Cocina'
  ];

  descuentosPorSegmento = {
    'Fabricante en Forma': 1,
    'Taller de Carpintería': 2,
    'Arquitecto / Despacho': 3,
    'Diseñador / Decorador': 4,
    'Ferreteras': 5,
    'Tienda de Cocina': 6
  };

  constructor() { }
}
