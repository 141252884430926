export function Debounce(timeInMs: number = 500) {
  return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {
    const map = new WeakMap();
    const originalMethod = descriptor.value;

    descriptor.value = function (...args) {
      // Execute logic before the method call
      let timer = map.get(this);
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        originalMethod.apply(this, args);
      }, timeInMs);
      map.set(this, timer);

      // Execute method
      // const result = originalMethod.apply(this, args);

      // Execute logic after the method call
    };

    return descriptor;
  };
}


