import { Component, OnInit } from '@angular/core';
import {FACTURAMA_DOWNLOAD_FILES_URL, FACTURAMA_TOKEN, URL_SERVICIOS} from 'src/app/config/config';
import { FacturasService } from 'src/app/services/facturas.service';
import { UsuarioService } from 'src/app/services/usuarios/usuario.service';
import swal from 'sweetalert';
import {Invoice2023Service} from '../../services/invoice2023.service';
import {catchError} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.css']
})
export class FacturacionComponent implements OnInit {

  // Variables
  urlservicios: string;
  token: string;

  terminoBusqueda: string;

  // Data
  facturas: any;
  loading = true;

  // Paginado
  paginaActual: number;
  totalPages: number;
  totalDeFacturas: number;
  facturamaDownloadUrl: string;

  constructor(
    private _facturasService: FacturasService,
    private _usuarioService: UsuarioService,
    private _facturamaService: Invoice2023Service,
    private http: HttpClient
  ) {
    this.facturamaDownloadUrl = FACTURAMA_DOWNLOAD_FILES_URL;
  }

  async ngOnInit(): Promise<void> {
    await this._facturamaService.setIssuerData();
    this.obtenerFacturasv4(1, 10);
    this.paginaActual = 1;
    this.urlservicios = URL_SERVICIOS;
    this.token = this._usuarioService.token;
  }

  paginaAnterior() {
    if ( this.paginaActual === 1 ) {
      return;
    }

    this.loading = true;
    this.paginaActual -= 1;
    this.obtenerFacturasv4( this.paginaActual, 10 );
  }

  paginaSiguiente() {
    if ( this.paginaActual === this.totalPages ) {
      return;
    }
    this.loading = true;
    this.paginaActual += 1;
    this.obtenerFacturasv4( this.paginaActual, 10 );
  }

  obtenerFacturasv4(page: number, limit: number = 10 ) {
    this.loading = true;
    this._facturamaService.getInvoices(page, limit)
      .pipe(
        catchError( (error) => {
            swal(
                'Error al obtener facturas',
                error.error.mensaje + ' | ' + error.error.errors.message,
                'error'
            );
            return error;
        }),
      )
      .subscribe(
      (resp: any) => {
        console.log('Facturas', resp);
        this.facturas = resp.map(invoice => {
         return {
           id: invoice.id,
           folio_number: invoice.folio,
           customer: { legal_name: invoice.taxName },
           total: invoice.total,
           created_at: invoice.date,
           uuid: invoice.uuid,
              status: invoice.status,
           type: 'I', // TODO find out how to get the type from facturama
         };
        });
        this.loading = false;
      });
  }
  obtenerFacturas( page: number, limit: number ) {
    this._facturasService.obtenerFacturas(page, limit).subscribe(
      (resp: any) => {

        this.facturas = resp.facturas.data;
        this.paginaActual = resp.facturas.page;
        this.totalPages = resp.facturas.total_pages;
        this.totalDeFacturas = resp.facturas.total_results;

    },
    (error) => {
      swal(
        'Error al obtener facturas',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  buscarFacturas() {
    let termino = this.terminoBusqueda;

    if ( this.terminoBusqueda === '' ) {
      termino = 'sinTermino';
    }

    this._facturasService.buscarFacturas( 1 , 10, termino ).subscribe(
      (resp: any) => {
        this.facturas = resp.facturas.data;
        this.paginaActual = resp.facturas.page;
        this.totalPages = resp.facturas.total_pages;
        this.totalDeFacturas = resp.facturas.total_results;
    },
    (error) => {
      swal(
        'Error al buscar facturas',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  facturamaDownloadFile(fileType: 'pdf' | 'xml', invoice: any) {
    const url = `${this.facturamaDownloadUrl}/${fileType}/issuedLite/${invoice.id}`;
    this.http.get(url, {
      headers: {
        'Authorization': `${FACTURAMA_TOKEN}`
      }
    }).subscribe((resp: any) => {
      this.downloadAsFile(resp.Content, `${invoice.uuid}.${fileType}`);
    });
  }


  obtenerPdf( idFactura ) {
    this._facturasService.obtenerPDF( idFactura ).subscribe(
      (resp: any) => {
        const tag = document.getElementById(`pdf-${ idFactura }`);
        tag.click();
    },
    (errors) => {

    });
  }

  obtenerXml( idFactura ) {
    this._facturasService.obtenerXML( idFactura ).subscribe(
      (resp: any) => {
        const tag = document.getElementById(`pdf-${idFactura}`);
        tag.click();
      },
      (errors) => {

      });
  }

  downloadAsFile(base64String: string, filename: string) {
    if (base64String.startsWith("JVB")) {
      base64String = "data:application/pdf;base64," + base64String;
      this.downloadFileObject(base64String, filename);
    } else {
      base64String = "data:application/xml;base64," + base64String;
      this.downloadFileObject(base64String, filename);
    }
  }

  downloadFileObject(base64String: string, filename: string) {
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = filename;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }



}
