import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Producto} from '../../../../models/producto.model';
import {UsuarioService} from '../../../../services/usuarios/usuario.service';
import {ProductoService} from 'src/app/services/productos/producto.service';
import {SubirArchivoService} from 'src/app/services/subirArchivo/subir-archivo.service';
import swal from 'sweetalert';

declare var $: any;

@Component({
  selector: 'app-agregar-producto',
  templateUrl: './agregar-producto.component.html',
  styleUrls: ['./agregar-producto.component.css']
})
export class AgregarProductoComponent implements OnInit {
  @Input()
  rutaDeCategorias: any;
  imagenSubir: File;
  imagenTemporal: string | ArrayBuffer;
  producto: Producto;

  codigo: string;
  nombre: string;
  familia: string;
  satCode: string;
  reference: string;
  img: string = null;
  precio: number;
  usuarioCreador: string;
  usuarioUltimaModificacion: string;
  imageLoading = false;

  @Output()
  actualizarFamilia: EventEmitter<any> = new EventEmitter();

  @Output()
  resetRutaDeCategorias: EventEmitter<any> = new EventEmitter();

  familias = [];

  formaValida = false;

  constructor(
    public _usuarioService: UsuarioService,
    public _productoService: ProductoService,
    private _subirArchivoService: SubirArchivoService
  ) {
  }

  ngOnInit() {
    this.usuarioCreador = this._usuarioService.id;
    this.usuarioUltimaModificacion = this._usuarioService.id;
    this.familias = this._productoService.familias;
  }

  resetearModal() {
    this.codigo = null;
    this.nombre = null;
    this.familia = null;
    this.img = null;
    this.precio = null;
    this.satCode = null;
    this.reference = null;
    this.resetRutaDeCategorias.emit();
    this.rutaDeCategorias = [];

    this.imagenSubir = null;
    this.imagenTemporal = null;
    this.producto = null;
    this.imageLoading = false;
  }

  agregarImagen() {
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.onclick = () => {
      this.imageLoading = true;
    };


    input.onchange = () => {

      let file: File = input.files[0];

      if (!file) {
        this.imagenSubir = null;
        this.imagenTemporal = null;

        return;
      }

      if (file.type.indexOf('image') < 0) {
        swal('Típo de archivo inválido', 'Seleccione una imágen', 'error');

        this.imagenSubir = null;
        return;
      }

      this.imagenSubir = file;
      let reader = new FileReader();
      let urlImagenTemporal = reader.readAsDataURL(file);

      reader.onloadend = () => {
        this.imagenTemporal = reader.result;
        this.imageLoading = false;
      };
    };

    input.click();
  }

  validarFormulario() {
  }

  registrarProducto(forma) {
    // Creamos el objeto producto con la información de la forma
    const producto = new Producto(
      forma.codigo,
      forma.nombre,
      forma.familia,
      forma.precio,
      this.usuarioCreador,
      this.usuarioUltimaModificacion,
      this.rutaDeCategorias,
      forma.reference,
      forma.satCode,
      null,
      null
    );

    //Ejecutamos la petición html para crear el producto
    this._productoService.registrarProducto(producto).subscribe(
      (resp: any) => {
        //Validamos existencia de imagen
        if (!this.imagenSubir) {
          //Refrescamos familia

          this.actualizarFamilia.emit(producto.familia);

          swal(
            'Producto registrado exitosamente',
            'El producto: ' +
            producto.nombre +
            ' se registró exitosamente, aunque no se selecciono una imagen para el mismo',
            'warning'
          );

          $('#nuevoProducto').modal('toggle');
          this.resetearModal();
        } else {
          //Hacemos la carga de la imagen con el id que se genero al crear el producto
          let producto = resp.producto;

          this._subirArchivoService
            .subirArchivo(this.imagenSubir, 'producto', producto._id)
            .then(resp => {
              swal(
                'Producto registrado exitosamente',
                'El producto: ' + producto.nombre + ' se registró exitosamente',
                'success'
              );

              //Refrescamos familia
              this.actualizarFamilia.emit(producto.familia);

              $('#nuevoProducto').modal('toggle');
              this.resetearModal();
            })
            .catch(error => {
              console.log(error);

              swal(
                'La información del producto se registró, pero ocurrió un error al subir la imagen',
                error.error.mensaje + ' | ' + error.error.errors.message,
                'warning'
              );

              $('#nuevoProducto').modal('toggle');
              this.resetearModal();
              return;
            });
        }
      },
      error => {
        swal(
          'Error al registrar producto',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      }
    );
  }


  openCategorizationEditor() {
    $('#nuevoProducto').modal('toggle');
    $('#nuevoProducto').on('hidden.bs.modal', function (event) {
      // Open your second one in here
      $('#modalAgregarCategoria').modal('toggle');
      $('#nuevoProducto').off('hidden.bs.modal');

      $('#modalAgregarCategoria').on('hidden.bs.modal', function (event) {
        $('#nuevoProducto').modal('toggle');
        $('#modalAgregarCategoria').off('hidden.bs.modal');
      });

    });
  }
}
