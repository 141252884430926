import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { UsuarioService } from '../usuarios/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class LogInventarioService {

  constructor(
    private _usuarioService: UsuarioService,
    private http: HttpClient
  ) { }

  obtenerLogsDeInventario( pagina: number, producto = '' ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/logInventario?token=${ token }&pagina=${ pagina }&producto=${ producto }`;

    return this.http.get( url );
  }

  crearLogDeInventario( log ){
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/logInventario?token=${ token }`;

    return this.http.post( url, log );
  }


}
