import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioService } from './usuarios/usuario.service';
import { URL_SERVICIOS } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class FacturasService {

  // Data
  formasDePago = [
    { value: '01', descripcion: 'Efectivo' },
    { value: '02', descripcion: 'Cheque nominativo' },
    { value: '03', descripcion: 'Transferencia electrónica de fondos' },
    { value: '04', descripcion: 'Tarjeta de crédito' },
    { value: '05', descripcion: 'Monedero electrónico' },
    { value: '06', descripcion: 'Dinero electrónico' },
    { value: '08', descripcion: 'Vales de despensa' },
    { value: '12', descripcion: 'Dación en pago' },
    { value: '13', descripcion: 'Pago por subrogación' },
    { value: '14', descripcion: 'Pago por consignación' },
    { value: '15', descripcion: 'Condonación' },
    { value: '17', descripcion: 'Compensación' },
    { value: '23', descripcion: 'Novación' },
    { value: '24', descripcion: 'Confusión' },
    { value: '25', descripcion: 'Remisión de deuda' },
    { value: '26', descripcion: 'Prescripción o caducidad' },
    { value: '27', descripcion: 'A satisfacción del acreedor' },
    { value: '28', descripcion: 'Tarjeta de débito' },
    { value: '29', descripcion: 'Tarjeta de servicios' },
    { value: '30', descripcion: 'Aplicación de anticipos' },
    { value: '31', descripcion: 'Intermediario pagos' },
    { value: '99', descripcion: 'Por definir' },
  ];

  metodosDePago = [
    { value: 'PUE', descripcion: 'Pago en una sola exhibición (Contado)' },
    // { value: 'PPD', descripcion: 'Pago en parcialidades o diferido (Total o Parcialmente a Crédito)' }
  ];

  usosCFDI = [
    { value: 'G01', 	descripcion: 'G01 - Adquisición de mercancias' },
    { value: 'G02', 	descripcion: 'G02 - Devoluciones, descuentos o bonificaciones' },
    { value: 'G03', 	descripcion: 'G03 - Gastos en general' },
    { value: 'I01', 	descripcion: 'I01 - Construcciones' },
    { value: 'I02', 	descripcion: 'I02 - Mobilario y equipo de oficina por inversiones' },
    { value: 'I03', 	descripcion: 'I03 - Equipo de transporte' },
    { value: 'I04', 	descripcion: 'I04 - Equipo de computo y accesorios' },
    { value: 'I05', 	descripcion: 'I05 - Dados, troqueles, moldes, matrices y herramental' },
    { value: 'I06', 	descripcion: 'I06 - Comunicaciones telefónicas' },
    { value: 'I07', 	descripcion: 'I07 - Comunicaciones satelitales' },
    { value: 'I08', 	descripcion: 'I08 - Otra maquinaria y equipo' },
    { value: 'D01', 	descripcion: 'D01 - Honorarios médicos, dentales y gastos hospitalarios' },
    { value: 'D02', 	descripcion: 'D02 - Gastos médicos por incapacidad o discapacidad' },
    { value: 'D03', 	descripcion: 'D03 - Gastos funerales' },
    { value: 'D04', 	descripcion: 'D04 - Donativos' },
    { value: 'D05', 	descripcion: 'D05 - Intereses reales efectivamente pagados por créditos hipotecarios(casa habitación)' },
    { value: 'D06', 	descripcion: 'D06 - Aportaciones voluntarias al SAR' },
    { value: 'D07', 	descripcion: 'D07 - Primas por seguros de gastos médicos' },
    { value: 'D08', 	descripcion: 'D08 - Gastos de transportación escolar obligatoria' },
    { value: 'D09', 	descripcion: 'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones' },
    { value: 'D10', 	descripcion: 'D10 - Pagos por servicios educativos(colegiaturas)' },
    { value: 'P01', 	descripcion: 'P01 - Por definir' }
  ];

  relacionEntreFacturas = [
    { value: '01', descripcion:	'Nota de crédito de los documentos relacionados' },
    { value: '02', descripcion:	'Nota de débito de los documentos relacionados' },
    { value: '03', descripcion:	'Devolución de mercancía sobre facturas o traslados previos' },
    { value: '04', descripcion:	'Sustitución de los CFDI previos' },
    { value: '05', descripcion:	'Traslados de mercancias facturados previamente' },
    { value: '06', descripcion:	'Factura generada por los traslados previos' },
    { value: '07', descripcion:	'CFDI por aplicación de anticipo' },
    { value: '08', descripcion:	'Factura generada por pagos en parcialidades' },
    { value: '09', descripcion:	'Factura generada por pagos diferidos' }
  ];


  constructor(
    private http: HttpClient,
    private _usuarioService: UsuarioService
  ) { }

  crearRecepcionDePago( recepcionDePago ){
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura/recepcionDePago?token=${token}`;

    return this.http.post( url, recepcionDePago );
  }

  crearNotaDeCredito( notaDeCredito ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura/egreso?token=${ token }`;

    return this.http.post( url, notaDeCredito );
  }

  cancelarFactura( idFactura ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura/cancelarFactura/${ idFactura }?token=${ token }`;

    return this.http.get(url);
  }

  facturarVenta( ventaId ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/venta/facturar/${ ventaId }?token=${ token }`;

    return this.http.get(url);
  }

  buscarFacturas( page: number, limit: number, termino: string ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura/buscarFacturas/${ termino }?token=${ token }&limit=${ limit }&page=${ page }`;

    return this.http.get( url );
  }

  obtenerFacturasPorType( type: string ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura/pagoParcial/${ type }?token=${ token }`;

    return this.http.get(url);
  }

  obtenerFacturas( page: number , limit: number ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura?token=${ token }&limit=${ limit }&page=${ page }`;

    return this.http.get(url);
  }

  obtenerPDF( idFactura ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura/pdf/${ idFactura }?token=${ token }`;

    return this.http.get(url);
  }

  obtenerXML( idFactura ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/factura/xml/${idFactura}?token=${token}`;

    return this.http.get(url);
  }

}
