import {Component, OnInit} from '@angular/core';
import {FacturasService} from 'src/app/services/facturas.service';
import {facturamaInvoiceStatus, Invoice2023Service} from '../../services/invoice2023.service';
import {catchError} from 'rxjs/operators';
import swal from 'sweetalert';
@Component({
  selector: 'app-cancelar-facturas',
  templateUrl: './cancelar-facturas.component.html',
  styleUrls: ['./cancelar-facturas.component.css']
})
export class CancelarFacturasComponent implements OnInit {

  // Data
  facturas: any;

  // Paginado
  paginaActual: number;
  totalPages: number;
  totalDeFacturas: number;
  loading = true;

  constructor(
    private _invoice2023Service: Invoice2023Service,
    private _facturasService: FacturasService
  ) { }

  async ngOnInit(): Promise<void> {
    await this._invoice2023Service.setIssuerData();
    this.obtenerFacturasv4(1, 10);
    this.paginaActual = 1;
  }

  paginaAnterior() {
    if (this.paginaActual === 1) {
      return;
    }

    this.loading = true;
    this.paginaActual -= 1;
    this.obtenerFacturasv4(this.paginaActual, 10);
  }

  paginaSiguiente() {
    if (this.paginaActual === this.totalPages) {
      return;
    }

    this.loading = true;
    this.paginaActual += 1;
    this.obtenerFacturasv4(this.paginaActual, 10);
  }

  public obtenerFacturasv4(page: number, limit: number) {
    this.loading = true;
    this._invoice2023Service.getInvoices(page, limit, facturamaInvoiceStatus.active)
      .pipe(
        catchError((error) => {
          swal(
            'Error cancelar factura',
            error.error.mensaje + ' | ' + error.error.errors.message,
            'error'
          );
          return error;
        }),
      )
      .subscribe(
      (resp: any) => {
        console.log('Facturas', resp);
        this.facturas = resp.map(invoice => {
          return {
            id: invoice.id,
            folio_number: invoice.folio,
            customer: { legal_name: invoice.taxName },
            total: invoice.total,
            created_at: invoice.date,
            uuid: invoice.uuid,
            status: invoice.status,
            type: 'I', // TODO find out how to get the type from facturama
          };
        });
        this.loading = false;
      });
  }

  obtenerFacturas(page: number, limit: number) {
    this._facturasService.obtenerFacturas(page, limit).subscribe(
      (resp: any) => {

        this.facturas = resp.facturas.data;
        this.paginaActual = resp.facturas.page;
        this.totalPages = resp.facturas.total_pages;
        this.totalDeFacturas = resp.facturas.total_results;

      },
      (error) => {
        swal(
          'Error al obtener facturas',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  cancelarFacturav4(factura) {
    if (!factura?.cancelMotive) {
     swal(
       'Se requiere un motivo de cancelación',
        'Por favor seleccione un motivo de cancelación',
        'warning'
     );
     return;
    }

    if (factura?.cancelMotive === '01' && !factura?.uuidReplacement) {
      swal(
        'Se requiere un UUID de reemplazo',
         'Para el motivo de cancelación 1, se requiere un UUID de reemplazo',
         'warning'
      );
      return;
    }
    this._invoice2023Service
      .cancelInvoice(factura.id, factura?.cancelMotive, factura?.uuidReplacement)
      .pipe(
        catchError((error) => {
          const standardError = (error?.error?.mensaje || error?.error?.errors?.message)
            ? error?.error?.mensaje + ' | ' + error?.error?.errors?.message
            : (error?.error?.text)
              ? error?.error?.text
              : error.message;
          swal(
            'Error al cancelar factura',
            standardError,
            'error'
          );
          return error;
        })
      )
      .subscribe(
      (resp: any) => {
        console.log('Respuesta de cancelación', resp);
      });

  }

  cancelarFactura( factura ) {
    const idFactura = factura.id;

    this._facturasService.cancelarFactura(idFactura).subscribe(
      (resp: any) => {
        this.obtenerFacturas( this.paginaActual, 10);
        swal(
          'Solicitud de cancelación exitosa',
          `La factura se encuentra bajo estatus: ${resp.facturaCancelada.status} y con un estatus de cancelación: ${resp.facturaCancelada.cancellation_status }`,
          'success'
        );

    },
    (error) => {
      swal(
        'Error al cancelar factura',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });

  }


}
