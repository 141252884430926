import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elemento-cuentas-cobrar',
  templateUrl: './elemento-cuentas-cobrar.component.html',
  styleUrls: ['./elemento-cuentas-cobrar.component.css']
})
export class ElementoCuentasCobrarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
