import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {InvoiceIssuerService} from '../../services/invoice-issuer.service';
import {catchError} from 'rxjs/operators';
import swal from 'sweetalert';
import {FiscalRegimeService} from '../../services/fiscal-regime.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-add-csd',
  templateUrl: './add-csd.component.html',
  styleUrls: ['./add-csd.component.css']
})
export class AddCsdComponent implements OnInit {

  invoiceIssuer: any; // TODO add proper interface
  fiscalRegimes: any;
  fiscalRegime: string;

  newIssuerForm = new FormGroup({
    name: new FormControl(''),
    rfc: new FormControl(''),
    fiscalRegime: new FormControl(''),
    cerFile: new FormControl(''),
    keyFile: new FormControl(''),
    csdPassword: new FormControl(''),
    postalCode: new FormControl(''),
  });

  constructor(
    private invoiceIssuerService: InvoiceIssuerService,
    private fiscalRegimeService: FiscalRegimeService,
  ) { }

  ngOnInit(): void {
    this.fiscalRegimes = this.fiscalRegimeService.getFiscalRegimes();
    this.getInvoiceIssuer();
  }

  private getInvoiceIssuer() {
    this.invoiceIssuerService.getInvoiceIssuer()
      .pipe(
        catchError((error) => {
          swal(
            'Error al obtener emisor',
            error.error.mensaje + ' | ' + error.error.errors.message,
            'error'
          );
          return error;
        })
      )
      .subscribe((resp: any) => {
        this.invoiceIssuer = resp?.id ? resp : null;
      });
  }

  fileReader(event, type: 'cerFile' | 'keyFile') {
    const reader = new FileReader();
    const [file] = event?.target?.files;
    if (!file) {
      return;
    }
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      const readerResult = reader.result;
      const base64File = btoa(readerResult as string);
      console.log(reader.result);
      console.log('###readerResult', base64File);
      this.newIssuerForm.patchValue({
       [type]: base64File
      });
    };
  }

  public createIssuer() {
    if (this.newIssuerForm.invalid) {
      swal(
        'El formulario no es valido',
        'Favor de revisar y llenar todos los campos',
        'warning'
      );
      return;
    }
    const invoiceIssuer = this.newIssuerForm.value;
    this.invoiceIssuerService.createInvoiceIssuer({
      ...invoiceIssuer,
      csdKey: invoiceIssuer.keyFile,
      csdCer: invoiceIssuer.cerFile,
      fiscalRegimeCode: invoiceIssuer.fiscalRegime,
      taxName: invoiceIssuer.name,
    })
      .pipe(
        catchError((error) => {
          swal(
            'Error al crear invoice issuer',
            error.error.mensaje + ' | ' + error.error.errors.message,
            'error'
          );
          return error;
        })
      )
      .subscribe((resp: any) => {
        this.getInvoiceIssuer();
        console.log('###Invoice issuer', resp);
        swal(
          'Invoice issuer creado',
          'El invoice issuer se creo correctamente',
          'success'
        );
      });
  }

  public deleteInvoiceIssuer() {
    this.invoiceIssuerService.deleteInvoiceIssuer(this.invoiceIssuer?.id)
      .pipe(
        catchError((error) => {
          swal(
            'Error al eliminar invoice issuer',
            error.error.mensaje + ' | ' + error.error.errors.message,
            'error'
          );
          return error;
        })
      )
      .subscribe((resp: any) => {
        this.getInvoiceIssuer();
        console.log('###Invoice issuer', resp);
        swal(
          'Invoice issuer eliminado',
          'El invoice issuer se elimino correctamente',
          'success'
        );
      });
  }

}
