import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProductoService} from '../../../services/productos/producto.service';
import {UsuarioService} from '../../../services/usuarios/usuario.service';
declare var $: any;
@Component({
  selector: 'app-agregar-categorizacion',
  templateUrl: './agregar-categorizacion.component.html',
  styleUrls: ['./agregar-categorizacion.component.css']
})
export class AgregarCategorizacionComponent implements OnInit {
  // data
 productCategorization: any = {};

  // variables
  selectedCategories = [];
  actualCategory = {};

  @Output()
  setRutaDeCategorias: EventEmitter<any[]> = new EventEmitter();

  constructor(
    private productService: ProductoService,
    private userService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.productCategorization = this.productService.categorizacion;
    this.actualCategory = this.productCategorization;
    console.log('@@actualCategory', this.actualCategory);
  }

  selectCategory(category: string) {
    if (this.selectedCategories.indexOf(category) >= 0) {
      return;
    }

    this.selectedCategories.push(category);
    this.actualCategory = this.actualCategory[category];
  }

  moveToCategorization(categorizationIndex: number) {
     const categorizationRoute = this.selectedCategories.slice(0, categorizationIndex + 1);
     this.actualCategory = this.productCategorization;

     this.selectedCategories = this.selectedCategories.slice(0, categorizationIndex + 1);

      categorizationRoute.forEach(categorization => {
        this.actualCategory = this.actualCategory[categorization];
      });
  }

  restartSelection() {
    this.selectedCategories = [];
    this.actualCategory = this.productCategorization;
  }

  resetModal() {
    this.selectedCategories = [];
    this.actualCategory = this.productCategorization;
    $('#modalAgregarCategoria').modal('toggle');
  }

  completeCategorization() {
    console.log(this.selectedCategories);
    this.setRutaDeCategorias.emit(this.selectedCategories);
    $('#modalAgregarCategoria').modal('toggle');
  }

}
